<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <!------------ box header ------------->
      <div class="flex-none border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="flex justify-between border-t border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
          <div class="flex items-center justify-start space-x-3 px-2 h-8">
            <p v-if="loading.getData" class="text-xs font-medium p-2 text-dark text-bold">Loading...</p>
            <p v-else class="text-xs font-medium p-2 text-dark text-bold">Group</p>
          </div>
        </div>
      </div>

      <!----------- box content ------------->
      <div class="flex-1 border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="box-content-height overflow-auto">
          <div v-if="data.groups.length < 1" class="flex w-full h-full justify-center items-center">
            <p class="text-xs">Tidak ada data.</p>
          </div>
          <table v-else class="table-fixed table-sticky border-collapse w-full">
            <!-----------TABLE HEAD------------>
            <thead>
              <tr class="d-theme-dark-bg">
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-24">Kode</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Deskripsi</th>
              </tr>
            </thead>
            <!-----------TABLE BODY------------>
            <tbody v-for="group in data.groups" :key="group.uuid">
              <!--group-->
              <tr class="cursor-pointer hover-bg-primary-transparent-25" @click="group.expanded = !group.expanded">
                <td class="border border-solid d-theme-border-grey-light p-2 font-medium" colspan="2">
                  <div class="flex items-center">
                    <feather-icon :icon="group.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" svg-classes="h-5 w-5" class="mr-2"/>
                    <span class="font-bold text-xs">{{ group.name }}</span>
                  </div>
                </td>
              </tr>
              <!--items-->
              <template v-if="group.expanded">
                <tr v-for="item in group.items" :key="item.uuid" @click="data.selected = item" class="cursor-pointer" :class="{'bg-primary-transparent-25': data.selected.id === item.id}">
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.kode_h }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-pre">{{ item.deskripsi }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>

      <!------------ box footer ------------->
      <div class="flex-none">
        <div class="flex flex-wrap items-center p-2 space-x-2">
          <vs-button v-if="$can('create_group_setup_report')" color="success" size="small" class="px-2" icon-pack="feather" icon="icon-plus" @click="showModalAdd">Tambah</vs-button>
          <vs-button v-if="$can('update_group_setup_report')" color="primary" size="small" class="px-2" icon-pack="feather" icon="icon-edit" @click="showModalEdit" :disabled="!data.selected.id">Edit</vs-button>
          <vs-button v-if="$can('delete_group_setup_report')" color="danger" size="small" class="px-2" icon-pack="feather" icon="icon-trash" @click="confirmDelete" :disabled="!data.selected.id">Delete</vs-button>
        </div>
      </div>
    </div>

    <!--modals-->
    <GroupAdd :isActive.sync="modalAdd.active" @success="getData"/>
    <GroupEdit :isActive.sync="modalEdit.active" :idReportGroup="modalEdit.idReportGroup" @success="getData"/>
  </div>
</template>

<script>
import SetupReportRepository from '@/repositories/accounting/setup-report-repository'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'GroupSection',
  components: {
    GroupAdd: () => import('@/views/pages/accounting/setup/setup-report/tabs/tab-setup-report/groups/GroupAdd'),
    GroupEdit: () => import('@/views/pages/accounting/setup/setup-report/tabs/tab-setup-report/groups/GroupEdit')
  },
  watch: {
    data: {
      deep: true,
      handler (value) {
        this.commitGroupSectionToStore(value)
      }
    }
  },
  mounted () {
    this.getData()
  },
  data () {
    return {
      loading: {
        getData: false
      },
      modalAdd: {
        active: false
      },
      modalEdit: {
        active: false,
        idReportGroup: null
      },
      data: {
        groups: [],
        selected: {}
      }
    }
  },
  methods: {
    getData () {
      this.loading.getData = true

      SetupReportRepository.getGroups()
        .then(response => {
          this.data.groups = this.buildGroups(_.cloneDeep(response.data.data))
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.getData = false
        })
    },

    delete () {
      const id = this.data.selected.id
      SetupReportRepository.deleteGroup(id)
        .then(response => {
          this.data.selected = {}
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    confirmDelete () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete() }
      })
    },

    buildGroups (data) {
      const generateGroupItems = (group) => {
        return _.map(_.filter(data, item => (item.group === group)), item => {
          item.uuid = uuid()
          item.selected = false
          return item
        })
      }

      const generateGroups = () => {
        return _.map(_.uniqBy(data, item => item.group), item => {
          return {
            uuid: uuid(),
            name: item.group,
            items: generateGroupItems(item.group),
            expanded: true
          }
        })
      }

      return generateGroups()
    },

    showModalAdd () {
      this.modalAdd.active = true
    },

    showModalEdit () {
      this.modalEdit.idReportGroup = this.data.selected.id
      this.modalEdit.active = true
    },

    commitGroupSectionToStore (value) {
      this.$store.commit('accounting/tabSetupReport/SET_GROUP_SECTION', _.cloneDeep(value))
    }
  },
  created () {
    this.commitGroupSectionToStore(this.data)
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  /*314*/
  height: calc(100vh - 265px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 288px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 234px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 251px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 274px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 220px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 233px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 256px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 202px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 188px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 211px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 157px);
}

/*table sticky*/
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>

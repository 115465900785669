<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <!------------ box header ------------->
      <div class="flex-none border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="flex justify-between border-t border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
          <div class="flex items-center justify-start space-x-3 px-2 h-8">
            <p v-if="loading.getData" class="text-xs font-medium p-2 text-dark text-bold">Loading...</p>
            <p v-else class="text-xs font-medium p-2 text-dark text-bold">COA ({{ data.items.length }} Item)</p>
          </div>
        </div>
      </div>

      <!----------- box content ------------->
      <div class="flex-1 border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="box-content-height overflow-auto">
          <div v-if="!idReportD || data.items.length < 1" class="flex w-full h-full justify-center items-center">
            <p v-if="!idReportD" class="text-xs">Pilih item detail terlebih dahulu.</p>
            <p v-else class="text-xs">Tidak ada data.</p>
          </div>
          <table v-else class="table-fixed table-sticky border-collapse w-full">
            <!-----------TABLE HEAD------------>
            <thead>
              <tr class="d-theme-dark-bg">
                <th class="text-xs px-2 py-0 border border-solid d-theme-border-grey-light text-center w-14">
                  <vs-checkbox size="small" class="text-xs" v-model="data.checkAll" @change="toggleCheckAll($event.target.checked)"></vs-checkbox>
                </th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center cursor-pointer w-24" @click="orderColumn('kode_coa')">Kode <span v-html="orderColumnIcon('kode_coa')"/></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center cursor-pointer" @click="orderColumn('group_coa')">Group <span v-html="orderColumnIcon('group_coa')"/></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center cursor-pointer" @click="orderColumn('nama_coa')">Nama <span v-html="orderColumnIcon('nama_coa')"/></th>
              </tr>
            </thead>
            <!-----------TABLE BODY------------>
            <tbody v-for="item in itemsOrdered" :key="item.uuid">
              <tr @click="item.checked = !item.checked" class="cursor-pointer font-semibold" :class="{'bg-primary-transparent-25': item.checked}">
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left" @click="item.checked = !item.checked">
                  <vs-checkbox size="small" class="text-xs" v-model="item.checked"></vs-checkbox>
                </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap" style="font-family: monospace">{{ item.kode_coa }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ item.group_coa }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ item.nama_coa }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!------------ box footer ------------->
      <div class="flex-none">
        <div class="flex flex-wrap items-center p-2 space-x-2">
          <vs-button v-if="$can('delete_coa_setup_report')" color="danger" size="small" class="px-2" icon-pack="feather" icon="icon-trash" @click="confirmDelete" :disabled="checkedItems.length < 1">Delete</vs-button>
          <vs-button color="primary" size="small" class="px-2" icon-pack="feather" icon="icon-copy" @click="copy" :disabled="checkedItems.length < 1">Copy</vs-button>
          <vs-button color="primary" size="small" class="px-2" icon-pack="feather" icon="icon-clipboard" @click="paste" :disabled="copiedCoaIds.length < 1">Paste <span v-if="copiedCoaIds.length > 0">{{ copiedCoaIds.length }} Item</span></vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SetupReportRepository from '@/repositories/accounting/setup-report-repository'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'CoaSection',
  watch: {
    data: {
      deep: true,
      handler (value) {
        this.commitCoaSectionToStore(value)
      }
    },
    idReportD (newVal, oldVal) {
      this.data.checkAll = false
      this.data.items = []
      if (newVal && !_.isEqual(newVal, oldVal)) {
        this.getData()
      }
    }
  },
  computed: {
    dataDetailSection () {
      return this.$store.state.accounting.tabSetupReport.detailSection
    },
    idReportD () {
      return this.dataDetailSection.selected.id
    },
    checkedItems () {
      return _.filter(this.data.items, item => item.checked)
    },
    itemsOrdered () {
      const columnOrder = _.pickBy(this.columnOrder, item => item != null)
      const columnKeys = _.keys(columnOrder)
      const columnOrders = _.values(columnOrder)
      return _.orderBy(this.data.items, columnKeys, columnOrders)
    }
  },
  data () {
    return {
      loading: {
        getData: false
      },
      columnOrder: {
        kode_coa: 'asc',
        group_coa: null,
        nama_coa: null
      },
      data: {
        items: [],
        checkAll: false
      },
      copiedCoaIds: []
    }
  },
  methods: {
    copy () {
      this.copiedCoaIds = _.map(this.checkedItems, item => item.id_coa)
      this.uncheckAll()
    },

    paste () {
      const copiedCoaIds = this.copiedCoaIds
      const currentCoaIds = _.map(this.data.items, item => item.id_coa)
      const coaIdsThatDoesNotExist = _.difference(copiedCoaIds, currentCoaIds)
      if (coaIdsThatDoesNotExist.length > 0) {
        this.$emit('paste', coaIdsThatDoesNotExist)
      } else {
        this.notifyWarning('Paste tidak dapat dilakukan karena semua COA sama.')
      }
    },

    uncheckAll () {
      this.data.items = _.map(this.data.items, item => {
        item.checked = false
        return item
      })
      this.data.checkAll = false
    },

    getData () {
      this.loading.getData = true

      SetupReportRepository.getDetailsCoa(this.idReportD)
        .then(response => {
          this.data.items = _.map(response.data.data, item => {
            item.checked = false
            item.uuid = uuid()
            return item
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.getData = false
        })
    },

    delete () {
      const params = {
        ids: _.map(this.checkedItems, item => item.id)
      }
      SetupReportRepository.deleteDetailCoa(params)
        .then(response => {
          this.emitRefreshed()
          this.data.checkAll = false
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    confirmDelete () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: `Yakin ingin menghapus ${this.checkedItems.length} data ini?`,
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete() }
      })
    },

    toggleCheckAll (isChecked) {
      this.data.items = _.map(this.data.items, item => {
        item.checked = isChecked
        return item
      })
    },

    orderColumnIcon (columnKey) {
      if (this.columnOrder[columnKey] == null) return null
      return this.columnOrder[columnKey] === 'asc' ? '&#8593;' : '&#8595;'
    },

    orderColumn (columnKey) {
      const currentOrder = this.columnOrder[columnKey]
      if (currentOrder == null) {
        this.columnOrder[columnKey] = 'asc'
      } else if (currentOrder === 'asc') {
        this.columnOrder[columnKey] = 'desc'
      } else if (currentOrder === 'desc') {
        this.columnOrder[columnKey] = null
      }
    },

    commitCoaSectionToStore (value) {
      this.$store.commit('accounting/tabSetupReport/SET_COA_SECTION', _.cloneDeep(value))
    },

    emitRefreshed () {
      this.$emit('refreshed')
    }
  },
  created () {
    this.commitCoaSectionToStore(this.data)
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  /*314*/
  height: calc(100vh - 265px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 288px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 234px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 251px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 274px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 220px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 233px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 256px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 202px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 188px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 211px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 157px);
}

/*table sticky*/
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>

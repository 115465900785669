<template>
  <div class="flex flex-col no-scroll-content">
    <!------------ header ------------->
    <div class="flex-none">
      <div class="p-0 vx-card shadow-none rounded-none border-solid d-theme-border-grey-light border border-b-0">
        <div class="flex h-auto">
          <ul class="flex items-center text-xs overflow-x-auto">
            <li v-for="(tabItem, index) in tab.items"
                :key="index"
                :class="{'text-primary font-bold': isTabActive(tabItem), 'hover-bg-primary-transparent-25': !isTabActive(tabItem)}"
                @click="tab.active = tabItem"
                class="cursor-pointer px-4 py-2 select-none whitespace-no-wrap border-solid d-theme-border-grey-light border-b-0 border-t-0 border-l-0 border-r">
              {{ tabItem }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!----------- content ------------->
    <div class="flex-grow">
      <div class="flex h-full">
        <TabSetupReport v-if="isTabActive('SETUP REPORT')"/>
      </div>
    </div>

  </div>
</template>

<script>
import TabSetupReport from '@/views/pages/accounting/setup/setup-report/tabs/tab-setup-report/TabSetupReport'

export default {
  name: 'SetupReport',
  components: {
    TabSetupReport
  },
  data () {
    return {
      tab: {
        items: ['SETUP REPORT'],
        active: 'SETUP REPORT'
      }
    }
  },
  methods: {
    isTabActive (tab) {
      return tab === this.tab.active
    }
  }
}
</script>

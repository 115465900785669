<template>
  <div class="w-full flex flex-col">
    <div class="flex-grow">
      <div class="flex h-full space-x-1">
        <div class="w-full sm:w-3/12 d-theme-dark-bg" :class="{'pointer-events-none opacity-50': isLoadingDetailsSection}">
          <GroupSection ref="groupSection"/>
        </div>

        <div class="w-full sm:w-5/12 d-theme-dark-bg">
          <DetailSection ref="detailSection"
                         @loading="onLoadingDetailSection"
                         @refreshed="refreshCoaSection"/>
        </div>

        <div class="w-full sm:w-4/12 d-theme-dark-bg">
          <CoaSection ref="coaSection"
                      :externalFilter="filterCoa"
                      @refreshed="refreshDetailSection"
                      @paste="onPaste"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GroupSection from '@/views/pages/accounting/setup/setup-report/tabs/tab-setup-report/groups/GroupSection'
import DetailSection from '@/views/pages/accounting/setup/setup-report/tabs/tab-setup-report/details/DetailSection'
import CoaSection from '@/views/pages/accounting/setup/setup-report/tabs/tab-setup-report/coa/CoaSection'
import moduleTabSetupReport from '@/store/modules/accounting/setup-report/tab-setup-report.store'

export default {
  name: 'TabSetupReport',
  components: {
    GroupSection,
    DetailSection,
    CoaSection
  },
  data () {
    return {
      isLoadingDetailsSection: false,
      copiedCoaIds: []
    }
  },
  computed: {
    filterCoa () {
      return {
        tipe: 'DETAIL'
      }
    }
  },
  methods: {
    onLoadingDetailSection (isLoading) {
      this.isLoadingDetailsSection = isLoading
    },

    onPaste (coaIds) {
      this.$refs.detailSection.saveDetailsCoa(coaIds)
    },

    refreshDetailSection () {
      this.$refs.detailSection.getData()
    },

    refreshCoaSection () {
      this.$refs.coaSection.getData()
    }
  },
  beforeCreate () {
    this.$store.registerModule(['accounting', 'tabSetupReport'], moduleTabSetupReport)
  },
  beforeDestroy () {
    this.$store.commit('accounting/tabSetupReport/RESET_STATE')
    this.$store.unregisterModule(['accounting', 'tabSetupReport'])
  }
}
</script>

import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/setupReport'

export default {
  getGroups (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/groups${queryParams}`)
  },

  showGroup (idGroup) {
    return httpClient.get(`${endpoint}/groups/${idGroup}`)
  },

  createGroup (params) {
    return httpClient.post(`${endpoint}/groups`, params)
  },

  updateGroup (params) {
    return httpClient.patch(`${endpoint}/groups`, params)
  },

  deleteGroup (id) {
    return httpClient.delete(`${endpoint}/groups/${id}`)
  },

  getDetails (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/details${queryParams}`)
  },

  showDetail (idReportD) {
    return httpClient.get(`${endpoint}/details/${idReportD}`)
  },

  createDetail (params) {
    return httpClient.post(`${endpoint}/details`, params)
  },

  createDetailBulk (params) {
    return httpClient.post(`${endpoint}/details/bulk`, params)
  },

  updateDetail (params) {
    return httpClient.patch(`${endpoint}/details`, params)
  },

  deleteDetail (id) {
    return httpClient.delete(`${endpoint}/details/${id}`)
  },

  getDetailsCoa (idReportD) {
    return httpClient.get(`${endpoint}/details/${idReportD}/coa`)
  },

  saveDetailsCoa (idReportD, params) {
    return httpClient.post(`${endpoint}/details/${idReportD}/coa`, params)
  },

  deleteDetailCoa (params) {
    return httpClient.post(`${endpoint}/details/coa`, params)
  }
}

<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <!------------ box header ------------->
      <div class="flex-none border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="flex justify-between border-t border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
          <div class="w-full flex items-center justify-between space-x-3 px-2">
            <p v-if="loading.getData" class="text-xs font-medium p-2 text-dark text-bold">Loading...</p>
            <p v-else class="text-xs font-medium p-2 text-dark text-bold">Detail ({{ data.items.length }} Item)</p>
            <p v-if="data.items.length > 0" class="text-xs font-light p-2 text-warning">(double click item to select COA)</p>
          </div>
        </div>
      </div>

      <!----------- box content ------------->
      <div @contextmenu.prevent="$refs.menu.open" class="flex-1 border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="box-content-height overflow-auto">
          <div v-if="!idReportGroup || data.items.length < 1" class="flex w-full h-full justify-center items-center">
            <p v-if="!idReportGroup" class="text-xs">Pilih item group terlebih dahulu.</p>
            <p v-else class="text-xs">Tidak ada data.</p>
          </div>
          <table v-else class="table-fixed table-sticky border-collapse w-full">
            <!-----------TABLE HEAD------------>
            <thead>
              <tr class="d-theme-dark-bg">
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-16">No</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Deskripsi</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-14">Jml</th>
              </tr>
            </thead>
            <!-----------TABLE BODY------------>
            <tbody v-for="item in data.items" :key="item.uuid">
              <tr @click="data.selected = item" @dblclick="showModalCoa" class="cursor-pointer select-none" :class="{'bg-primary-transparent-25': data.selected.id === item.id}">
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.no_urut }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-pre break-all">{{ item.deskripsi }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-pre" @click.stop><vs-checkbox size="small" class="text-xs" v-model="item.checked" @change="toggleCheck($event.target.checked, item)"/></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!------------ box footer ------------->
      <div class="flex-none">
        <div class="flex flex-wrap items-center p-2 space-x-2">
          <vs-button v-if="$can('create_detail_setup_report')" color="success" size="small" class="px-2" icon-pack="feather" icon="icon-plus" @click="showModalAdd" :disabled="!idReportGroup">Tambah</vs-button>
          <vs-button v-if="$can('update_detail_setup_report')" color="primary" size="small" class="px-2" icon-pack="feather" icon="icon-edit" @click="showModalEdit" :disabled="!data.selected.id">Edit</vs-button>
          <vs-button v-if="$can('delete_detail_setup_report')" color="danger" size="small" class="px-2" icon-pack="feather" icon="icon-trash" @click="confirmDelete" :disabled="!data.selected.id">Delete</vs-button>
        </div>
      </div>
    </div>

    <!--modals-->
    <DetailAdd :isActive.sync="modalAdd.active" :idReportGroup="idReportGroup" @success="getData"/>
    <DetailEdit :isActive.sync="modalEdit.active" :idReportD="modalEdit.idReportDetail" @success="getData"/>

    <vs-popup class="sm:popup-w-70 popup-content-no-padding"
              title="Pilih COA"
              :active="modalCoa.active"
              v-on:update:active="modalCoa.active = $event">
      <Coa :selectable="true" :externalFilter="filterCoa" multi-select :disable-ids="data.selected.coa_ids" @selected="onSelectedCoa"/>
    </vs-popup>

    <vs-popup class="sm:popup-w-70 popup-content-no-padding"
              title="Pilih COA Untuk Ditambahkan"
              :active="modalBulkAddViaCoa.active"
              v-on:update:active="modalBulkAddViaCoa.active = $event">
      <Coa ref="modalCoaBulkAdd" :selectable="true" :externalFilter="filterCoa" multi-select @selected="onSelectedCoaForBulkAdd"/>
    </vs-popup>

    <!--context menu-->
    <vue-context ref="menu">
      <li>
        <a class="flex items-center text-sm" @click="showModalBulkAddViaCoa">
          <feather-icon icon="PlusIcon" svgClasses="w-4 h-4"/>
          <span class="ml-2 text-xs">Insert Beberapa Account Menjadi Format Report</span>
        </a>
      </li>
    </vue-context>

  </div>
</template>

<script>
import SetupReportRepository from '@/repositories/accounting/setup-report-repository'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import { VueContext } from 'vue-context'

export default {
  name: 'DetailSection',
  components: {
    VueContext,
    DetailAdd: () => import('@/views/pages/accounting/setup/setup-report/tabs/tab-setup-report/details/DetailAdd'),
    DetailEdit: () => import('@/views/pages/accounting/setup/setup-report/tabs/tab-setup-report/details/DetailEdit'),
    Coa: () => import('@/views/pages/master/coa/Coa')
  },
  watch: {
    data: {
      deep: true,
      handler (value) {
        this.commitDetailSectionToStore(value)
      }
    },
    idReportGroup (newVal, oldVal) {
      this.data.selected = {}
      if (newVal && !_.isEqual(newVal, oldVal)) {
        this.getData()
      }
    },
    'loading.getData' (isLoading) {
      this.emitLoading(isLoading)
    }
  },
  computed: {
    dataGroupSection () {
      return this.$store.state.accounting.tabSetupReport.groupSection
    },
    idReportGroup () {
      return this.dataGroupSection.selected.id
    },
    filterCoa () {
      return {
        tipe: 'DETAIL'
      }
    }
  },
  data () {
    return {
      loading: {
        getData: false
      },
      modalAdd: {
        active: false
      },
      modalEdit: {
        active: false,
        idReportDetail: null
      },
      modalCoa: {
        active: false
      },
      modalBulkAddViaCoa: {
        active: false
      },
      data: {
        items: [],
        selected: {}
      }
    }
  },
  methods: {
    toggleCheck (isChecked, item) {
      item.status_jumlah = isChecked ? 'YES' : 'NO'
      this.update(item)
    },

    getData () {
      this.loading.getData = true

      const params = { id_report_group: this.idReportGroup }
      SetupReportRepository.getDetails(params)
        .then(response => {
          this.data.items = _.map(response.data.data, item => {
            item.checked = item.status_jumlah === 'YES'
            item.uuid = uuid()
            return item
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.getData = false
        })
    },

    update (item) {
      const params = {
        id: item.id,
        no_urut: item.no_urut,
        deskripsi: item.deskripsi,
        status_jumlah: item.status_jumlah
      }
      SetupReportRepository.updateDetail(params)
        .then(response => {})
        .catch(error => {
          console.log(error)
        })
    },

    delete () {
      const id = this.data.selected.id
      SetupReportRepository.deleteDetail(id)
        .then(response => {
          this.data.selected = {}
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    saveDetailsCoa (coaIds) {
      const idReportD = this.data.selected.id
      const params = { coaIds: coaIds }
      SetupReportRepository.saveDetailsCoa(idReportD, params)
        .then(response => {
          // emit refreshed
          this.emitRefreshed()

          // update coa ids to current selected item
          const index = _.findIndex(this.data.items, item => item.id === this.data.selected.id)
          this.data.items[index].coa_ids.push(...coaIds)
          this.data.selected = this.data.items[index]
          this.notifySuccess('COA berhasil ditambahkan')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    bulkAdd (coaIds) {
      const params = {
        id_report_group: this.idReportGroup,
        coa_ids: coaIds
      }
      SetupReportRepository.createDetailBulk(params)
        .then(response => {
          this.getData()
          this.notifySuccess('Data berhasil ditambahkan')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    confirmDelete () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete() }
      })
    },

    onSelectedCoa (items) {
      const coaIds = _.map(items, item => item.id)
      this.saveDetailsCoa(coaIds)
      this.modalCoa.active = false
    },

    onSelectedCoaForBulkAdd (items) {
      const coaIds = _.map(items, item => item.id)
      this.bulkAdd(coaIds)
      this.modalBulkAddViaCoa.active = false
      this.$refs.modalCoaBulkAdd.clearChecked()
    },

    showModalCoa () {
      this.modalCoa.active = true
    },

    showModalBulkAddViaCoa () {
      this.modalBulkAddViaCoa.active = true
    },

    showModalAdd () {
      this.modalAdd.active = true
    },

    showModalEdit () {
      this.modalEdit.idReportDetail = this.data.selected.id
      this.modalEdit.active = true
    },

    commitDetailSectionToStore (value) {
      this.$store.commit('accounting/tabSetupReport/SET_DETAIL_SECTION', _.cloneDeep(value))
    },

    emitRefreshed () {
      this.$emit('refreshed')
    },

    emitLoading (isLoading) {
      this.$emit('loading', isLoading)
    }
  },
  created () {
    this.commitDetailSectionToStore(this.data)
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  /*314*/
  height: calc(100vh - 265px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 288px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 234px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 251px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 274px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 220px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 233px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 256px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 202px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 188px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 211px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 157px);
}

/*table sticky*/
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>

const getDefaultState = () => {
  return {
    groupSection: {},
    detailSection: {},
    coaSection: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    SET_GROUP_SECTION (state, payload) {
      state.groupSection = payload
    },
    SET_DETAIL_SECTION (state, payload) {
      state.detailSection = payload
    },
    SET_COA_SECTION (state, payload) {
      state.coaSection = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
